import React from 'react';
import { PageProps } from 'gatsby';
import { WithTranslation, withTranslation } from 'react-i18next';

import Layout, { HeroSectionColors, LayoutOptions } from '../../../components/Layout/Layout';

import { IconTypes } from '../../../components/Icon/Icon';
import InfoBlock from '../../../components/InfoBlock/InfoBlock';
import { ButtonLinkSize } from '../../../components/ButtonLink/ButtonLink';
import { LocaleNameSpaceKeys } from '../../../localization/translations';
import { getPathFromRouteKey } from '../../../shared/utils/getPathFromRouteKey';
import RouteKeys from '../../../../config/RouteKeys';

import './style.scss';

type Props = PageProps & WithTranslation;

const Resources: React.FC<Props> = (props) => {
  const { t } = props;

  const layoutOptions: LayoutOptions = {
    seo: {
      title: t('resources.seo.title'),
    },
    page: {
      title: t('resources.heading'),
    },
    heroSection: {
      options: {
        accentColor: HeroSectionColors.SKY_BLUE,
        heroImage: {
          fileName: 'hero-image-13.jpg',
          alt: 'Image',
        },
      },
    },
  };

  const infoBlocks = [
    {
      to: getPathFromRouteKey(RouteKeys.BORROWER),
      key: 'clientPortal',
      icon: IconTypes.CLIENT_PORTAL,
    },
    {
      to: getPathFromRouteKey(RouteKeys.CUSTOMER_CENTRE.PREPAYMENT_CALCULATOR),
      key: 'prepaymentCalculator',
      icon: IconTypes.PREPAYMENT_CALCULATOR,
    },
    {
      to: getPathFromRouteKey(RouteKeys.CUSTOMER_CENTRE.MORTGAGE_SERVICE_DOCUMENTS),
      key: 'mortgageServicingDocuments',
      icon: IconTypes.MORTGAGE_SERVICING_DOCS,
    },
    {
      to: getPathFromRouteKey(RouteKeys.CUSTOMER_CENTRE.RESOURCES.FINANCIAL_LITERACY),
      key: 'financialLiteracy',
      icon: IconTypes.FINANCIAL_LITERACY,
    },
    {
      to: getPathFromRouteKey(RouteKeys.CUSTOMER_CENTRE.RESOURCES.FINANCIAL_TOOLS_RESOURCES),
      key: 'financialTools',
      icon: IconTypes.FINANCIAL_TOOLS_RESOURCES,
    },
    // {
    //   to: getPathFromRouteKey(RouteKeys.CUSTOMER_CENTRE.COVID19_FAQ),
    //   key: 'covid19Support',
    //   icon: IconTypes.COVID19_SUPPORT,
    // },
  ];

  return (
    <Layout options={layoutOptions} className="Resources">
      <div className="grid-container">
        <div className="row row-wrap">
          {infoBlocks.map((infoBlock) => (
            <div className="column column-30 InfoBlockWrapper" key={infoBlock.key}>
              <InfoBlock
                icon={infoBlock.icon}
                buttonOptions={{
                  to: infoBlock.to,
                  label: t('resources.buttonLabel'),
                  iconOptions: {
                    icon: IconTypes.ARROW_NEXT,
                  },
                  styleOptions: {
                    isInline: true,
                    size: ButtonLinkSize.LARGE,
                  },
                  ariaLabel: t(`resources.${infoBlock.key}.ariaButtonLabel`),
                }}
                heading={<h2 className="h3">{t(`resources.${infoBlock.key}.heading`)}</h2>}
              >
                <p>{t(`resources.${infoBlock.key}.body`)}</p>
              </InfoBlock>
            </div>
          ))}
          {[...Array(3 - (infoBlocks.length % 3))].map((item, index) => (
            <div
              aria-hidden="true"
              className="column column-30 InfoBlockWrapper"
              key={`empty-${index}`}
            />
          ))}
        </div>
      </div>
    </Layout>
  );
};

export default withTranslation(LocaleNameSpaceKeys.CUSTOMER_CENTRE)(Resources);
